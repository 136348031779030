import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { ResponseError } from 'types'
import DidymeServices from 'services/DidymeServices'
import { ExportState } from 'services/DidymeServices/types'
import terms from 'common/terms'
import { allErrorsMatcher } from './matchers/matchers'

export enum FeedbackType {
  validation = 'validation'
}

export interface FeedbackState {
  feedback?: ResponseError;
  message: string;
  messageReady?: boolean;

}

const initialState: FeedbackState = {
  feedback: undefined,
  message: '',
  messageReady: false,
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.feedback = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setMessageReady: (state, action: PayloadAction<boolean>) => {
      state.messageReady = action.payload
    },
    snackbarClose: state => {
      state.feedback = undefined
      state.message = ''
      state.messageReady = false
    },
  },
  extraReducers: builder => {
    builder.addMatcher(allErrorsMatcher, (state, action) => {
      state.feedback = action.payload as ResponseError
    })
    builder.addMatcher(isAnyOf(DidymeServices.fetchExportLink.fulfilled), (state, action) => {
      if (action.payload.task_status === ExportState.failure) {
        state.messageReady = true
        state.feedback = { code: 0, data: { message: terms.Export.failure } }
      }
    })
  },
})

export const {
  setError, setMessage, setMessageReady, snackbarClose,
} = feedbackSlice.actions

export default feedbackSlice.reducer
