import { Grid, Switch } from '@mui/material'
import { ReactElement } from 'react'
import './GaiaSwitch.scss'
import { useDispatch, useSelector } from 'react-redux'
import { ObjectSchemas } from 'services/DidymeServices/types'
import { setSchema } from 'reducers/didyme'
import { RootState } from 'Store'

export default function GaiaSwitch(): ReactElement {
  const dispatch = useDispatch()
  const { currentSchema, fetchLoading } = useSelector((state: RootState) => state.didyme)

  const handleSwitch = () => {
    const newSchema = currentSchema === ObjectSchemas.Gaia ? ObjectSchemas.Ve : ObjectSchemas.Gaia
    dispatch(setSchema(newSchema))
  }
  return (
    <div className={`switch-wrapper${fetchLoading ? ' disabled' : ''}`}>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid className="label" item>V2</Grid>
        <Grid item>
          <Switch
            value="checked"
            onChange={handleSwitch}
            disabled={fetchLoading}
          />
        </Grid>
        <Grid className="label" item>VE</Grid>
      </Grid>
    </div>
  )
}
