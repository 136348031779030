import { store } from 'Store'
import { dateToYMD } from 'helpers'
import { cloneDeepWith, noop } from 'lodash'
import {
  FilterOperator,
  ObjectField, ObjectParameters, ObjectType,
  GraphParameters,
} from './types'

const isFieldHidden = (field: ObjectField, hiddenFields: ObjectField[]) => hiddenFields?.some(f => f.slug
  === field.slug)

const removeHiddenFields = (object: ObjectType) => {
  const { hiddenFields } = store.getState().didyme

  let copy = { ...object }
  Object.keys(hiddenFields).forEach(field => {
    copy = cloneDeepWith(copy, val => {
      if (field === val?.uniqueId) {
        const fieldsToShow = val.fields.filter((f: ObjectField) => !isFieldHidden(f,
          hiddenFields[val.uniqueId]))
        const newObj = { ...val, fields: fieldsToShow }
        return newObj
      }
      return noop()
    })
  })

  return copy
}

const buildFetchParameters = ({
  params, objectDate, sortedField, filterList,
} : ObjectParameters) => {
  const filters = filterList.map(filter => ({
    joinPath: filter.column.joinPath,
    operator: FilterOperator[filter.operator as keyof typeof FilterOperator],
    value: filter.value,
  }))
  const date = objectDate ? dateToYMD(objectDate) : undefined

  const ordering = sortedField.joinPath.toString()
    ? [{ ...sortedField, kind: sortedField.kind }] : undefined

  const treeWithFields = removeHiddenFields(params)

  return {
    tree: { ...treeWithFields }, date, ordering, filters,
  }
}

const buildFetchGraphParameters = ({
  params, objectDate, filterList,
} : GraphParameters) => {
  const filters = filterList.map(filter => ({
    joinPath: filter.column.joinPath,
    operator: FilterOperator[filter.operator as keyof typeof FilterOperator],
    value: filter.value,
  }))
  const date = objectDate ? dateToYMD(objectDate) : undefined

  const treeWithFields = removeHiddenFields(params)

  return {
    tree: { ...treeWithFields }, date, filters,
  }
}

export {
  buildFetchParameters,
  buildFetchGraphParameters,
}
