/* eslint-disable camelcase */
import { get, post } from '@osrdata/app_core/dist/requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import URI from 'services/uri'
import {
  Estimation, ObjectParameters, ObjectType, SearchParameters,
  GraphParameters,
} from './types'
import { buildFetchParameters, buildFetchGraphParameters } from './utils'

const searchObjects = createAsyncThunk(
  'didyme/searchObjects',
  async (params: SearchParameters, thunkApi) => {
    try {
      const from = { from: params.from ? `${params.schemas}:${params.from}` : null }
      const response = await get(`/${URI.didyme}/search`, { ...params, ...from })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchExportLink = createAsyncThunk(
  'didyme/fetchExportLink',
  async (id :number, thunkApi) => {
    try {
      const response = await get(`/${URI.didyme}/extractions/${id}`)
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchObjects = createAsyncThunk(
  'didyme/fetchObjects',
  async ({
    params, objectDate, page, sortedField, filterList, get_csv = false,
  } : ObjectParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchParameters({
        params, objectDate, page, sortedField, filterList,
      })
      const pageExport = get_csv ? { get_csv: true } : { page }
      const response = await post(`/${URI.didyme}/gaia-fetch/`, { ...fetchParameters },
        { params: { ...pageExport } })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const findShortestPath = createAsyncThunk(
  'didyme/findShortestPath',
  // todo refacto
  async ({
    fromType, toType, via = '', schemas, isNewObject = false,
  }: {fromType: ObjectType, toType: ObjectType, via?: string, schemas: string,
    isNewObject?: boolean}, thunkApi) => {
    try {
      const from = `${schemas}:${fromType.type}`
      const to = `${schemas}:${toType.type}`
      const by_field = via ? { by_field: via } : {}
      const response = await get(`/${URI.didyme}/paths/${from}/${to}`, { n_paths: 1, schemas, ...by_field })
      return {
        paths: response.paths, fromType, via, toType, isNewObject,
      }
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const preFetchObjects = createAsyncThunk(
  'didyme/preFetchObjects',
  async ({
    params, objectDate, page, sortedField, filterList,
  } : ObjectParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchParameters({
        params, objectDate, page, sortedField, filterList,
      })
      const response: Estimation = await post(`/${URI.didyme}/gaia-fetch-check/`, { ...fetchParameters })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const fetchObjectsGraph = createAsyncThunk(
  'didyme/fetchObjectsGraph',
  async ({
    params, objectDate, filterList,
  } : GraphParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchGraphParameters({
        params, objectDate, filterList,
      })
      const response = await post(`/${URI.didyme}/graph/`, { ...fetchParameters })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)
const fetchExploratoryGraph = createAsyncThunk(
  'didyme/fetchExploratoryGraph',
  async ({
    params, objectDate, filterList, currentSchema,
  } : GraphParameters, thunkApi) => {
    try {
      const fetchParameters = buildFetchGraphParameters({
        params, objectDate, filterList,
      })
      const response = await post(`/${URI.didyme}/graph/`, { ...fetchParameters }, {
        params: {
          schema: currentSchema,
        },
      })
      return response
    } catch (e: any) {
      return thunkApi.rejectWithValue({
        data: e.response.data,
        code: e.response.status,
      })
    }
  },
)

const DidymeServices = {
  searchObjects,
  fetchObjects,
  fetchExportLink,
  findShortestPath,
  preFetchObjects,
  fetchObjectsGraph,
  fetchExploratoryGraph,
}

export default DidymeServices
