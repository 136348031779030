export const MAIN_API = {
  proxy: 'https://gateway.dev.dgexsol.fr',
  api: process.env.REACT_APP_USE_LOCAL_BACKEND
    ? `http://${window.location.hostname}:8000`
    : 'https://gateway.dev.dgexsol.fr',
  version: '0.0.1.20200408.1',
}

export const PORTAL_URL = {
  url: 'https://dev.dgexsol.fr/',
}

export default MAIN_API
