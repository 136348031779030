import { store } from 'Store'
import { cloneDeepWith, noop } from 'lodash'
import { setLastSearch } from 'reducers/didyme'
import { EMPTY_SEARCH_TREE } from 'reducers/utils'
import { LinkedObject, ObjectField, ObjectType } from 'services/DidymeServices/types'
import { UnknownObject } from 'types'

type UpdateTree = (currentTree: ObjectType, objectToChange: ObjectType | ObjectField,
  lastSearch: {type: string, id: string}) => ObjectType
type Path = {fromType: ObjectType, toType: ObjectType}

const cloneDeepWithId = (tree: ObjectType, fromReducer: boolean): ObjectType => {
  if (tree?.join?.length) {
    const join = tree.join.map(obj => ({ ...obj, tree: cloneDeepWithId(obj?.tree as ObjectType, fromReducer) }))
    const uniqueId = tree?.uniqueId || crypto.randomUUID()
    if (!tree?.uniqueId && !tree?.isIntermediate && !fromReducer) {
      store.dispatch(setLastSearch({ type: tree.type, id: uniqueId }))
    }
    return {
      ...tree,
      join,
      uniqueId,
    }
  }
  const uniqueId = tree?.uniqueId || crypto.randomUUID()
  if (!tree?.uniqueId && !tree?.isIntermediate && !fromReducer) {
    store.dispatch(setLastSearch({ type: tree.type, id: uniqueId }))
  }
  return { ...tree, uniqueId }
}

const buildObjectLink = (object: ObjectType, fromReducer = false): ObjectType => {
  const path = object.path ? object.path : object

  const tree = (path?.estimatedCompleteness !== undefined ? path.tree : path.tree?.join[0]?.tree) as ObjectType
  const estimatedCompleteness = path?.estimatedCompleteness || path.tree?.join[0].estimatedCompleteness
  const newTree = cloneDeepWithId(tree, fromReducer)
  const children = { children: path.tree?.join[0]?.children } || {}

  const link = 'lastJoinField' in path || !('link' in path) ? path.tree?.join[0]?.link : path.link
  return {
    ...children,
    estimatedCompleteness,
    link: { ...link },
    tree: { ...newTree, matchedField: object.matchedField },
  } as ObjectType
}

const updateSearchTree: UpdateTree = (currentTree, objectToInsert, lastSearch) => {
  let newObject = objectToInsert
  if ('slug' in objectToInsert) {
    newObject = {
      tree: {
        type: (objectToInsert.type as LinkedObject).slug,
        fields: [{} as ObjectField],
      },
    } as ObjectType
  }
  const objectTree = buildObjectLink(newObject as ObjectType)
  const lastIndex = currentTree.join?.length

  if (lastIndex) {
    const updatedChild = cloneDeepWith(currentTree, val => {
      if (val?.type === lastSearch.type && (val?.uniqueId === lastSearch.id)) {
        return { ...val, join: [...val.join, objectTree] }
      }
      return noop()
    })
    return updatedChild
  }
  return { ...currentTree, join: [objectTree] }
}

const getNestedObject = (nestedObject: ObjectType, type = '', lastObjectWanted = false): ObjectType => {
  const lastObject: ObjectType = nestedObject
  if (type && type === nestedObject.type) {
    return lastObject
  }

  if (nestedObject?.tree?.isIntermediate === false && !type && !lastObjectWanted) {
    return lastObject?.tree as ObjectType
  }

  if (nestedObject?.path) {
    return getNestedObject(nestedObject.path?.tree, type, lastObjectWanted)
  }
  if (nestedObject?.tree) {
    return getNestedObject(nestedObject.tree, type, lastObjectWanted)
  }
  if (nestedObject?.join?.length) {
    const length = nestedObject.join?.length
    return getNestedObject(nestedObject.join?.[length - 1] as ObjectType, type, lastObjectWanted)
  }

  return lastObject as ObjectType
}

const deleteFromSearchTree = (currentTree: ObjectType, objectToDelete: ObjectType,
  parentObject: ObjectType | undefined) => {
  if (!parentObject) {
    if (objectToDelete.join?.length) {
      return { ...getNestedObject(objectToDelete.join[0] as ObjectType) }
    }
    return EMPTY_SEARCH_TREE
  }
  const currentObject = parentObject?.tree || parentObject
  return cloneDeepWith(currentTree, val => {
    if (val?.type === currentObject.type && val?.uniqueId === currentObject.uniqueId) {
      const typeToDelete = objectToDelete?.tree?.type || objectToDelete.type
      const deleteJoin = objectToDelete.join || objectToDelete?.tree?.join || []

      const index = currentObject.join?.findIndex(child => getNestedObject(child)?.type === typeToDelete)
      return { ...val, join: [...val.join?.toSpliced(index, 1), ...deleteJoin] }
    }
    return noop()
  })
}

const buildPaths = (objectTree: ObjectType, paths: Path[] = []) => {
  if (!objectTree?.join?.length) return paths

  objectTree.join?.forEach(child => {
    const nestedChild = getNestedObject(child)
    paths.push({ fromType: objectTree, toType: nestedChild })
    buildPaths(child)
  })

  return paths
}

const getPaths = (objectTree: ObjectType) => {
  const objectPaths: Path[] = []
  if (objectTree.join?.length === 0) {
    return objectPaths
  }

  return buildPaths(objectTree)
}

const replacePaths = (searchTree: ObjectType, fromType: ObjectType, toType: ObjectType,
  via: string, paths: ObjectType[],
  isNewObject: boolean) => {
  // todo remove cloneDeep
  let oldToType: ObjectType
  cloneDeepWith(searchTree, val => {
    if (val?.type === toType.type && val?.uniqueId === toType.uniqueId) {
      oldToType = val
    }
  })

  let newToPath
  if (isNewObject) {
    newToPath = { ...paths[0] }
  } else {
    newToPath = cloneDeepWith({ ...paths[0].tree?.join[0] }, value => {
      if (value?.type === toType.type) {
        return { ...value, uniqueId: oldToType.uniqueId, join: [...oldToType?.join] }
      }
      return noop()
    })
  }
  const newJoin = buildObjectLink(newToPath, isNewObject)
  const newTree = cloneDeepWith({ ...searchTree }, value => {
    if (value?.type === fromType.type && value?.uniqueId === fromType.uniqueId) {
      const filteredJoin = via ? value?.join || []
        : value?.join?.filter((child: ObjectType) => getNestedObject(child)?.type
      !== toType.type) || []

      return { ...value, join: [...filteredJoin, newJoin] }
    }
    return noop()
  })
  return [newTree, (getNestedObject(newJoin, toType.type))]
}

const getIntermediateFields = (currentObject: ObjectType, fields: UnknownObject = {}) => {
  if (currentObject?.tree?.isIntermediate && currentObject?.tree?.uniqueId) {
    fields[currentObject?.tree?.uniqueId] = currentObject?.tree?.fields
  }

  if (currentObject?.tree?.join?.length) {
    fields = { ...fields, ...getIntermediateFields(currentObject?.tree?.join[0] as ObjectType) }
  }

  return fields
}

export {
  buildObjectLink, deleteFromSearchTree, getIntermediateFields, getNestedObject, getPaths,
  replacePaths, updateSearchTree, buildPaths,
}
