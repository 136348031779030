import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getIntermediateFields, replacePaths } from 'helpers/treeUtils'
import DidymeServices from 'services/DidymeServices'
import { Graph, parse } from 'dotparser-normalized'
import {
  CsvExport,
  Estimation,
  ExportState,
  Filter,
  HiddenField,
  ObjectField,
  ObjectResults,
  ObjectSchemas,
  ObjectType,
  SearchResults,
  Sort,
  ResultView,
  LastSearch,
} from 'services/DidymeServices/types'
import {
  EMPTY_ESTIMATION,
  EMPTY_EXPORT, EMPTY_OBJECTS, EMPTY_RESULTS, EMPTY_SEARCH_TREE, EMPTY_SORT,
} from './utils'

interface DidymeState {
  searchResults: SearchResults;
  searchTree: ObjectType;
  searchDate: string;
  searchLoading: boolean;
  objectsList: ObjectResults;
  fetchLoading: boolean;
  sortedField: Sort;
  filters: Filter[];
  filterMenuOpen: boolean;
  extractionId: number;
  csvExport: CsvExport;
  hiddenFields: HiddenField;
  open: string[];
  fetchEstimation: Estimation;
  pathLoading: boolean;
  lastSearch: LastSearch,
  currentSchema: ObjectSchemas;
  resultView: ResultView;
  objectsGraph: Graph[];
  exploratoryGraph: Graph[]
  isSimpleGraphLoaded: boolean
  isExploratoryGraphLoaded: boolean
}

const initialState: DidymeState = {
  searchResults: EMPTY_RESULTS,
  searchTree: EMPTY_SEARCH_TREE,
  searchDate: new Date().toDateString(),
  searchLoading: false,
  objectsList: EMPTY_OBJECTS,
  fetchLoading: false,
  sortedField: EMPTY_SORT,
  filters: [],
  filterMenuOpen: false,
  extractionId: 0,
  csvExport: EMPTY_EXPORT,
  hiddenFields: {},
  open: [],
  fetchEstimation: EMPTY_ESTIMATION,
  pathLoading: false,
  lastSearch: { type: '', id: '' },
  currentSchema: ObjectSchemas.Gaia,
  resultView: ResultView.table,
  objectsGraph: [],
  exploratoryGraph: [],
  isSimpleGraphLoaded: false,
  isExploratoryGraphLoaded: false,
}

export const didymeSlice = createSlice({
  name: 'didyme',
  initialState,
  reducers: {
    setTree: (state, action: PayloadAction<ObjectType>) => {
      state.searchTree = action.payload
    },
    setSearchDate: (state, action: PayloadAction<string>) => {
      state.searchDate = action.payload
    },
    resetSearchResults: state => {
      state.searchResults = EMPTY_RESULTS
    },
    resetSearch: state => {
      state.searchResults = EMPTY_RESULTS
      state.objectsList = EMPTY_OBJECTS
      state.sortedField = EMPTY_SORT
      state.filters = []
      state.filterMenuOpen = false
      state.extractionId = 0
      state.csvExport = EMPTY_EXPORT
      state.extractionId = 0
      state.fetchLoading = false
      state.open = []
      state.resultView = ResultView.table
      state.isSimpleGraphLoaded = false
      state.isExploratoryGraphLoaded = false
    },
    setSort: (state, action: PayloadAction<Sort>) => {
      state.sortedField = action.payload
    },
    showFilterMenu: (state, action: PayloadAction<boolean>) => {
      state.filterMenuOpen = action.payload
    },
    setOpen: (state, action: PayloadAction<string>) => {
      const index = action.payload
      if (state.open.includes(index)) {
        const newOpen = [...state.open]
        const accordionIndex = state.open.indexOf(index)
        newOpen.splice(accordionIndex, 1)
        state.open = [...newOpen]
      } else {
        state.open = ([...state.open, index])
      }
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
    setHiddenFields: (state, action: PayloadAction<{key: string, field: ObjectField | ObjectField[]}>) => {
      const { key, field } = action.payload
      const newHiddenFields = { ...state.hiddenFields }

      // todo refacto
      if (newHiddenFields[key] === undefined) {
        newHiddenFields[key] = Array.isArray(field) ? field : [field]
      } else {
        if (Array.isArray(field)) {
          return
        }
        const indexField = state.hiddenFields[key].findIndex(hidden => hidden.slug === field.slug)
        if (indexField === -1) {
          newHiddenFields[key].push(field)
        } else {
          newHiddenFields[key].splice(indexField, 1)
        }
      }

      state.hiddenFields = { ...newHiddenFields }
    },
    setLastSearch: (state, action: PayloadAction<LastSearch>) => {
      state.lastSearch = action.payload
    },
    setSchema: (_, action: PayloadAction<ObjectSchemas>) => ({
      ...initialState,
      currentSchema: action.payload,
    }),
    setResultView: (state, action: PayloadAction<ResultView>) => {
      state.resultView = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(DidymeServices.fetchObjectsGraph.fulfilled, (state, action) => {
      const datatest = parse(action.payload)
      state.isSimpleGraphLoaded = true
      state.objectsGraph = datatest
    })
    builder.addCase(DidymeServices.fetchExploratoryGraph.fulfilled, (state, action) => {
      const datatest = parse(action.payload)
      state.isExploratoryGraphLoaded = true
      state.exploratoryGraph = datatest
    })
    builder.addCase(DidymeServices.searchObjects.pending, state => {
      state.searchLoading = true
    })
    builder.addCase(DidymeServices.searchObjects.fulfilled, (state, { payload }) => {
      state.searchResults = payload
      state.searchLoading = false
    })
    builder.addCase(DidymeServices.searchObjects.rejected, state => {
      state.searchLoading = false
    })
    builder.addCase(DidymeServices.fetchObjects.pending, state => {
      state.fetchLoading = true
    })
    builder.addCase(DidymeServices.fetchObjects.fulfilled, (state, { payload }) => {
      if (payload.extraction_id) {
        state.extractionId = payload.extraction_id
        return
      }
      state.objectsList = payload
      state.fetchLoading = false
    })
    builder.addCase(DidymeServices.fetchObjects.rejected, state => {
      state.fetchLoading = false
    })
    builder.addCase(DidymeServices.fetchExportLink.fulfilled, (state, { payload }) => {
      state.csvExport = payload
      if (payload.task_status !== ExportState.pending) {
        state.fetchLoading = false
      }
    })
    builder.addCase(DidymeServices.fetchExportLink.rejected, state => {
      state.fetchLoading = false
    })
    builder.addCase(DidymeServices.findShortestPath.pending, state => {
      state.pathLoading = true
    })
    builder.addCase(DidymeServices.findShortestPath.fulfilled, (state, { payload }) => {
      const {
        fromType, toType, paths, via, isNewObject,
      } = payload
      state.pathLoading = false
      const newTree = replacePaths(state.searchTree, fromType, toType, via, paths, isNewObject)
      state.searchTree = { ...newTree[0] }
      if (isNewObject) {
        state.lastSearch = { ...state.lastSearch, id: newTree[1].uniqueId }
      }
      const intermediateFields = (getIntermediateFields(paths[0]?.path?.tree?.join[0] as ObjectType))
      const hidden: HiddenField = {}
      Object.entries(intermediateFields).forEach(([key, value]) => {
        hidden[key] = value as ObjectField[]
      })
      state.hiddenFields = { ...state.hiddenFields, ...hidden }
    })
    builder.addCase(DidymeServices.findShortestPath.rejected, state => {
      state.pathLoading = false
    })
    builder.addCase(DidymeServices.preFetchObjects.fulfilled, (state, { payload }) => {
      state.fetchEstimation = payload
    })
  },
})

export const {
  setTree, setSearchDate, resetSearch, setSort, showFilterMenu, setFilters,
  resetSearchResults, setHiddenFields, setOpen, setLastSearch, setSchema,
  setResultView,
} = didymeSlice.actions

export default didymeSlice.reducer
